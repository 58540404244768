import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router';
import { DocumentsContainer } from './containers/documents-container';
import RouteNotFound from '@common/components/route-not-found';

import './documents.scss';

export const documentsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[path, `${path}/:folderId`, `${path}/filter/:filter`]} component={DocumentsContainer} />
      <Route component={RouteNotFound} />
    </Switch>
  );
};

export default documentsRouter;
