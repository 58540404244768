import React, { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { DocumentsHeaderActionButtons } from '../../components/documents-header-action-buttons';
import { SubNavigationBar, TopNavigationBar } from '@common/components/navigation-bar';
import Container from '@common/components/container';
import { DocumentsBrowser } from '../../components/documents-browser';
import { usePageTracker } from '@common/hooks/usePageTracker';
import { useSearchParams } from '@common/hooks/use-search-params';
import { getPageTrackingEventName } from '../../utils';
import { getDocument } from '../../selectors';
import { Filters } from '../../constants';

import type { DocumentsRouteProps } from '../../types';
import type { StoreState } from '@common/types/store';

const BREADCRUMBS_LIMIT = 3;

const mapStateToProps = (state: StoreState, { match: { params } }: DocumentsRouteProps) => {
  const currentDocument = params.folderId ? getDocument(state, params.folderId) : undefined;

  return {
    currentFolder: currentDocument?.is_folder ? currentDocument : undefined,
  };
};

const connector = connect(mapStateToProps);

type DocumentsContainerReduxProps = ConnectedProps<typeof connector>;

type DocumentsContainerProps = DocumentsContainerReduxProps & DocumentsRouteProps;

export const UnwrappedDocumentsContainer = ({ match, currentFolder }: DocumentsContainerProps) => {
  usePageTracker(getPageTrackingEventName(match.params.filter));

  const { t } = useTranslation();
  const { searchParams, setSearchParam } = useSearchParams<{ search?: string }>();

  const createNewFolderPath = (folderId: string) => `/networks/${match.params.networkId}/documents/${folderId}`;

  type Breadcrumbs = Partial<ComponentProps<typeof TopNavigationBar>['breadcrumbs']>;

  const breadcrumbs = useMemo<Breadcrumbs>(() => {
    const newBreadcrumbs: Breadcrumbs = [{
      name: t('documents:my_documents'),
      path: `/networks/${match.params.networkId}/documents`,
    }];

    if (match.params.filter) {
      newBreadcrumbs.push({ name: t('documents:filter', { context: match.params.filter || 'all' }) });

      return newBreadcrumbs;
    }

    if (currentFolder) {
      if (currentFolder.personal_root_user_id && currentFolder.type !== 'personal_folder') {
        newBreadcrumbs.push({
          name: t('documents:personal_documents'),
          path: `/networks/${match.params.networkId}/documents/personal`,
        });
      }

      // @ts-expect-error
      if (currentFolder?.parent_folders) {
        // @ts-expect-error
        const isBreadcrumbsLimitExceeded = currentFolder.parent_folders.length > BREADCRUMBS_LIMIT - 1;
        // @ts-expect-error
        const breadcrumbsLimitSliceStart = currentFolder.parent_folders.length - (BREADCRUMBS_LIMIT - 1);

        if (isBreadcrumbsLimitExceeded) {
          newBreadcrumbs.push({ name: '...' });
        }

        // @ts-expect-error
        currentFolder.parent_folders
          ?.reverse()
          .slice(isBreadcrumbsLimitExceeded ? breadcrumbsLimitSliceStart : 0)
          // @ts-expect-error
          .forEach(({ id, name }) => {
            newBreadcrumbs.push({
              name,
              path: createNewFolderPath(id),
            });
          });
      }

      // Add current folder (without link because you're already in there anyway)
      newBreadcrumbs.push({
        name: currentFolder.type === 'personal_folder'
          ? t('documents:personal_documents')
          : currentFolder.name,
      });
    }

    return newBreadcrumbs;
  }, [currentFolder, match.params.filter]);

  const handleSetSearchTerm = (newSearchTerm: Parameters<typeof setSearchParam>[1]) => setSearchParam(
    'search',
    newSearchTerm,
    `/networks/${match.params.networkId}/documents`,
  );

  // Check if folder is personal
  const personalRootUserId = currentFolder?.personal_root_user_id || undefined;

  return (
    <div className="DocumentsContainer">
      <SubNavigationBar title={t('core:tab_documents')}>
        <SubNavigationBar.Item
          exact
          icon="folder"
          path={`/networks/${match.params.networkId}/documents`}
          title={t('documents:filter_all')}
          isActive={(m, { pathname }) => /^\/networks\/\d+\/documents\/?\d*(?![\w/])/.test(pathname) && !searchParams.search}
        />
        <SubNavigationBar.Item
          icon="access_time"
          path={`/networks/${match.params.networkId}/documents/filter/${Filters.RECENT}`}
          title={t('documents:filter_recents')}
        />
        <SubNavigationBar.Item
          icon="star"
          path={`/networks/${match.params.networkId}/documents/filter/${Filters.FAVORITES}`}
          title={t('documents:filter_favorites')}
        />
        <SubNavigationBar.Item
          icon="delete"
          path={`/networks/${match.params.networkId}/documents/filter/${Filters.TRASH}`}
          title={t('documents:filter_trash')}
        />
      </SubNavigationBar>

      <Container name="Documents">
        <TopNavigationBar
          breadcrumbs={breadcrumbs}
          title={t('documents:filter', { context: searchParams.search ? 'search' : match.params.filter || 'all' })}
          action={(
            <DocumentsHeaderActionButtons
              params={match.params}
              onNewSearchTerm={handleSetSearchTerm}
              searchTerm={searchParams.search}
              currentFolder={currentFolder}
              searchBoxPlaceholder={t('documents:search_placeholder')}
              userId={personalRootUserId}
            />
          )}
        />

        <Container.Content>
          <DocumentsBrowser
            getNextFolderPath={createNewFolderPath}
            searchTerm={searchParams.search}
          />
        </Container.Content>
      </Container>
    </div>
  );
};

export const DocumentsContainer = connector(UnwrappedDocumentsContainer);
